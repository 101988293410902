import { arrayOf, object, shape, string, number, bool } from 'prop-types'
import { graphql, Link, withPrefix } from 'gatsby'
import Helmet from 'react-helmet'
import React, { Fragment } from 'react'

import BuyButton from '../components/BuyButton'
import config from '../../config/website'

const ProductTemplate = ({ data: { sanityProduct } }) => {
  const { id, name, cover, creator, price, available, body, images } =
    sanityProduct
  const siteLogo = withPrefix('/logos/logo-vv.svg')
  const backURL = '/'
  const description = body?.fr.reduce(
    (res, item, index) =>
      (res +=
        (index ? '<br>' : '') +
        item?.children.reduce(
          (res2, child) => (res2 += child?.text || ''),
          ''
        )),
    ''
  )

  return (
    <>
      <Helmet title={`${name} | ${config.siteTitle}`} />
      <article className="pb5">
        <header className="dt w-100">
          <div
            style={{ backgroundImage: `url('${cover.asset.url}')` }}
            className="bg-fs2 bg-vvood white vh-100 dtc tc v-mid ph3 ph4-m ph5-l"
          >
            <h1 className="f2 white f-subheadline-ns measure pt3 mb0 b">
              {name}
            </h1>
            <h2 className="f6 white f5-ns fw2 tracked">
              <Link to={backURL} replace>
                <img
                  src={siteLogo}
                  className="dib w3  br-100"
                  alt={config.siteName}
                />
              </Link>
            </h2>
          </div>
        </header>
        <div className="ph3 ph4-m ph5-l">
          <p className="f1 tc b white">{name}</p>
          <p className="f6 tc fw2 tracked">
            Pièce unique signée <span className="b">{creator}</span>
          </p>
          <p
            className="lh-copy f6 f5-m f5-l fw3 tracked measure center pv2"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {config.shopEnabled && (
            <div className="lh-copy measure center pb4 dt">
              {available ? (
                <Fragment>
                  <div className="dtc v-mid ph3 lh-solid">
                    <h3 className="f5 f4-m f3-l fw9 white">{price}€</h3>
                  </div>
                  <div className="dtc v-mid ph3">
                    <BuyButton id={id} name={name} price={price} />
                  </div>
                </Fragment>
              ) : (
                <div className="f5 f4-m f3-l fw9 white pb3">
                  <span>Vendu</span>
                </div>
              )}
            </div>
          )}
          <div className="f5 f3-m lh-copy">
            <div className="cf ml3-l">
              {images &&
                images.map((image) => (
                  <div
                    key={image.asset.url}
                    className="fl w-100 w-50-l pr3-l pb3"
                  >
                    <img
                      className="db w-100"
                      src={image.asset.url}
                      alt={name}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="tc pt5">
            <Link
              to={backURL}
              replace
              className="f6 link dim br1 ba ph3 pv2 dib bg-vvood white"
            >
              Retour
            </Link>
          </div>
        </div>
      </article>
    </>
  )
}

ProductTemplate.propTypes = {
  data: shape({
    sanityProduct: shape({
      id: string,
      name: string,
      cover: object,
      creator: string,
      price: number,
      available: bool,
      body: object,
      images: arrayOf(object),
    }).isRequired,
  }).isRequired,
}

export default ProductTemplate

export const query = graphql`
  query ProductQuery($slug: String) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      id
      name
      cover {
        asset {
          url
        }
      }
      creator
      price
      available
      images {
        asset {
          url
        }
      }
      body {
        fr {
          children {
            text
          }
        }
      }
    }
  }
`
